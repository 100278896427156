import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { get } from "../../api/axios";
import { Pagination } from "antd";
const Payouts = () => {
  // render: (role) => (role == null ? "" : role.name),
  const columns = [
    {
      title: " رقم الحجز",
      dataIndex: "id",
    },
    {
      title: " حالة الحجز",
      dataIndex: "reservation",
      render: (reservation) => reservation?.status || "",
    },
    {
      title: " بيانات الحجز ",
      dataIndex: "reservation",
      render: (reservation) => (
        <span>
          تاريخ انشاء الحجز:
          <br />
          {reservation?.date_of_creation || "غير متوفر"}
          <br />
          وقت الحجز:
          <br />
          {reservation?.time_of_creation || "غير متوفر"}
          <br />
          سياسة الالغاء:
          <br />
          {reservation?.cancellation_policy || "غير متوفر"}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات الوصول والمغادرة ",
      dataIndex: "reservation",
      render: (reservation) => (
        <span>
          تاريخ الوصول والمغادرة
          <br />
          {reservation?.arrives_leaves?.date || "غير متوفر"}
          <br />
          وقت الوصول:
          <br />
          {reservation?.arrives_leaves?.arriving_time || "غير متوفر"}
          <br />
          وقت المغادرة:
          <br />
          {reservation?.arrives_leaves?.leaving_time || "غير متوفر"}
          <br />
          نوع الاقامة:
          <br />
          {reservation?.arrives_leaves?.type || "غير متوفر"}
          <br />
        </span>
      ),
    },
    {
      title: " بيانات السعر والدفع ",
      dataIndex: "reservation",
      render: (reservation) => (
        <span>
          {reservation?.prices?.subTotal || "غير متوفر"}
          <br />
          الخصم (عرض)
          <br />
          {reservation?.prices?.offers_amount || 0} ريال
          <br />
          الخصم (كود خصم)
          <br />
          {reservation?.prices?.coupon_amount || 0} ريال
          <br />
          رسوم الخدمة:
          <br />
          {reservation?.prices?.commission_amount || 0} ريال
          <br />
          المجموع:
          <br />
          {reservation?.prices?.totalPrice || 0} ريال
          <br />
        </span>
      ),
    },
    {
      title: (
        <>
          تاريخ انشاء الحوالة
          <br />
          رقم الحوالة
          <br />
          مبلغ الحوالة
          <br />
        </>
      ),
      render: (record) => (
        <span>
          {record?.payout_date || "غير متوفر"}
          <br />
          {record?.id || "غير متوفر"}
          <br />
          {record?.amount || "غير متوفر"}
        </span>
      ),
    },
    {
      title: " بيانات الوحدة ",
      dataIndex: "reservation",
      render: (reservation) => (
        <span>
          الوحدة:
          {reservation?.property?.name || "غير متوفر"}
          <br />
          المدينة:
          {reservation?.property?.city || "غير متوفر"}
          <br />
          الحي:
          {reservation?.property?.district || "غير متوفر"}
          <br />
          كود الوحدة:
          <br />
          {reservation?.property?.code || "غير متوفر"}
          <br />
        </span>
      ),
    },
    {
      title: "   بيانات المضيف  ",
      dataIndex: "reservation",
      render: (reservation) => (
        <span>
          الاسم:
          {reservation?.owner?.name || "غير متوفر"}
          <br />
          الجوال:
          {reservation?.owner?.phone || "غير متوفر"}
          <br />
          البريد الإلكتروني:
          {reservation?.owner?.email || "غير متوفر"}
        </span>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get("payouts").then((res) => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`payouts?page=${pageNumber}`).then((res) => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                //     x: 1350,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payouts;
